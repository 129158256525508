/* .App {
  text-align:center;
  animation-duration: 30s;
  animation-fill-mode: forwards;
  backface-visibility: hidden; 
  animation-name: moveTrain;
}
  

.App-logo {
  height: 50vmin;
  pointer-events: none;
  border: 1px solid red;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 30s linear;
  }
} */

/*.App-header {
  background-color: #282c34; 
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: red;
  border:10px black solid;
}*/


/*@keyframes moveTrain {
  0% {
    transform: translate (-185px, -159px);
  }
  100% {
    transform: translate (300px, 250px);
  }
} */

#root{
  background-color:rgb(213, 236, 240)
}

body{
  width:100%;
  height:100vh;
  margin:auto;
  box-sizing:border-box;
  overflow:hidden;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

button{
  background-color:rgb(159, 211, 159);
  cursor:pointer;
}

  button:hover{
    transform:scale(1.1)
  }

marquee{
  height:100vh;
}

::-webkit-scrollbar {
  display: none;
}

/* .app-item {
	position: relative;
  width:vw;
}

.app-logo {
	position: absolute;
	top: 0;
	left: 0;
  height:40vmin;
}

@keyframes moveTrain {
	0% {
		top: 0;
    left: 0;
	}
  33%{
    top: 33vh;
    right:0;
  }
  66%{
    top: 66vh;
    left:0;
  }
  	100% {
  		bottom: 0;
      right:0;
  	}
}

.app-item {
	animation-duration: 5s;
	animation-fill-mode: forwards;
	backface-visibility: hidden;
	animation-name: moveTrain;
	position: absolute; 
} */


.mainGame{
  display:grid;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: 100vh;
  column-gap:0.3vw;
  margin:auto;
}

.notePic{
  max-width:90%;
  max-height:10vh;
  margin-top:10px;
  margin-bottom:-25px;
}

/* .mainGame > div{
  border:black 2px solid;
} */


.noYouSure{
 display:none
}

.youSureMessage{
  position:fixed;
  justify-content:center;
  border:1px solid black;
  left:15%;
  right:15%;
  margin-top:5vh;
  padding:0 1vh;
  z-index:15;
  background-color:grey;
}

.settings{
  height:80%;
  margin: 1vh 5%;
  cursor: pointer;
}

.statistics{
  height:80%;
  margin: 1vh 1%;
  cursor: pointer;
}

  .settings:hover{
    transform:scale(1.15)
  }

  .statistics:hover{
    transform:scale(1.15)
  }

.settingsScreen{
  background-color:rgb(148, 189, 151);
  height:90%;
  width:80%;
  border-radius:3vw;
}

.heading{
  margin:auto;
  text-align:center;
  text-decoration: underline;
}

.settingsLevel{
  width:75%;
  margin:3vw auto;
  
}

.settingsButton{
  border:1px solid black;
  border-radius:1px;
  width:80%;
  padding: .1vw 1vw .5vw 1vw;
  background-color:rgb(16, 85, 42);
  color:rgb(168, 217, 178);
  cursor:pointer;
  font-size:2vw
}

  .settingsButton:hover{
    transform:scale(1.1)
  }

  .settingsButton:active{
    transform:scale(0.9)
  }

.offSettingsButton{
  border:1px solid black;
  border-radius:1px;
  width:80%;
  padding: .1vw 1vw .5vw 1vw;
  background-color:rgb(55, 62, 58);
  color:rgb(168, 217, 178);
  cursor:pointer;
  font-size:2vw
}

.offSettingsButton:hover{
  transform:scale(1.1)
}


.upgradeGrid{
  display:flex;
  flex-wrap:wrap;
  height:55%;
  overflow-y:auto;
  margin:auto;
  justify-content:center;
}

.spoilerMode{
  margin:1vw;
  scale:0.5;
  border:none;
}

.gridImage{
  height:3.5vw;
  width:3.5vw;
  padding:1vw 0.7vw;
  margin:1.4px;
  background-color:rgb(170, 206, 171);
  border-radius:1vw;
  cursor:pointer;
}


  .gridImage:hover{
    background-color:rgb(136, 200, 138);
  }

.preGridImage{
  height:3.5vw;
  width:3.5vw;
  padding:1vw 0.7vw;
  margin:1.4px;
  background-color:rgb(159, 171, 160);
  border-radius:1vw;
  cursor:pointer;
}

.bigWords{
  margin-bottom:-25px;
  margin-left:1.5px;
}
.littleWords{
  margin-bottom:-5px;
  padding-left:2px;
}

.parenth{
  font-size:10px;
}

.lilStatsHeading{
  margin-left:1.5px;
  margin-top:0px;
  margin-bottom:8px;
}

.sarahClicker{
  text-align:center;
  box-sizing:border-box;
}

.userTitle{
  margin-top:3vh;
}

.sarahCounter{
  margin-top:5vh;
}

.smilesCounter{
  padding:0;
  margin-bottom:0;
}

.perSecond{
  padding:0;
  margin-top:0;
}

.sarahFace{
  width:22vw;
  justify-content:center;
  align-items:auto;
  margin:4vh auto;
  cursor:pointer;
}

  .sarahFace:active{
    transform:scale(0.95);
  }

  .sarahFace:hover{
    animation-duration:0.5s;
    animation-name:sarahFaceHover;

  }

  @keyframes sarahFaceHover {
    20% {
      transform:scale(1.05)
    }
    33%{
      transform:scale(1)
    }
    55%{
      transform:scale(1.02)
    }
    100% {
        transform:scale(1)
      }
  }

  .menuBar{
    display:flex;
    height:8vh;
    padding-bottom:1vh;
  }

  .automatedClicks{
    overflow-y:visible;
  }



  .automatedClickItem{
    padding:0.3vh;
    margin:2vh;
    display:flex;
    cursor:pointer;
    margin-bottom:1vh;
    border-radius:0.5vh;
    box-sizing:border-box;
    overflow-x:auto;
    /* overflow-y:hidden; */
    height:5vh;
    border:1px solid black;
    background-color:rgb(159, 188, 179);
    width:75%;
  }

  .automatedPicture{
    width:4vh;
    height:auto;
    padding:0 .2vw;
    object-fit:cover;
    object-position:center;
    rotate:-7deg;
  }


  .goldenPicture{
    width:8vw;
    height:auto;
    position:absolute;
    animation-name: appear, spin;
    animation-duration: 3s, 3s;
    animation-iteration-count: 1, infinite;
    animation-timing-function: linear; 
    box-shadow:10px black;
    z-index:10;
    cursor:pointer;
    }

    .lucky{
      visibility:hidden;
      opacity:0;
      position:absolute;
      bottom:10%;
      left:45%;
      z-index:5;
      background-color:rgb(128, 174, 110);
      box-shadow:.3vw .3vw .3vw grey;
      padding:.1vw 1vw;
      border-radius:1vw;
      text-align:center;
      animation-name:hi-and-bye,grow-and-go;
      animation-duration:1.7s;
      animation-timing-function:linear;
    }
  

    @keyframes drop{
      
      15%{
        transform:translateY(0)
      }
      50%{
        transform:translateY(-1vw)
      }
      75%{
        transform:translateY(-3vw)
      }
      100%{
        transform:translateY(-8vw)
      }
    }

    @keyframes hi-and-bye{
      0%{
        visibility:visible;
        opacity:0;
      } 
      15%{
        opacity:1;
      }
      50%{
        opacity:0.66;
      }
      75%{
        opacity:0.33;
      }
      100%{
        opacity:0;
      }
    }

    @keyframes grow-and-go{
      0%{
        transform:scale(1)
      } 
      15%{
        transform:scale(1.2)
      }
      50%{
        transform:scale(1.05)
      }
      75%{
        transform:scale(0.9)
      }
      100%{
        transform:scale(0.7)
      }
    }


  @keyframes appear{
    from{
      opacity:0
    } 
    to{
      opacity:1
    }
  }

  @keyframes spin {
    0% {
        transform:rotate(0eg);
    }
    25% {
        transform:rotate(-25deg);
    }
    50%{
      transform:rotate(0deg);
    }
    75%{
      transform:rotate(25deg);
    }
    100%{
      transform:rotate(0deg)
    }
}
  


  .upgradeItems{
    display:flex;
    flex-wrap:wrap;
    row-gap:3px;
    column-gap:3px;
  }

  .upgradePicture{
    width:4.95vh;
    height:4.95vh;
    object-fit:unset;
    padding:.5vh;
    border-radius:1vh;
    margin:.25vh;
    cursor:pointer;
  }

  .upgradeTwo{
    position:relative;
    cursor:pointer;
    border:3px solid rgb(30, 145, 30);
    background-color:rgb(80, 80, 80);
  } 
    .upgradeTwo:hover{
      transform:scale(1.05)
    }

  .upgradeOne{
    position:relative;
    cursor:pointer;
    border:3px solid rgb(28, 29, 28);
    background-color:rgb(80, 80, 80)
  } 
  .upgradeZero{
    display:none
  } 

  .upgradeBlurb{
    position:absolute;
    right:34%;
    width:350px;
    top:12vh;
    height:auto;
    padding:0.1vw 0.3vw;
    background-color:rgb(172, 158, 158);
    border-radius:2vw;
  }

  .upgradeFirstRow{
    display:flex;
    row-gap:0;
  }

  .upgradeBlurbPicture{
    width:6vh;
    height:6vh;
    object-fit:contain;
    padding:.5vh;
    border-radius:1vh;
    margin:.25vh;
    cursor:pointer;
  }

  .upgradeName{
    margin-bottom:3vh;
  }

  .noBlurb{
   display:none;
  }

  h6{
    font-size:0.8rem;
    height:4vh;
  }

  h6:last-of-type{
    margin-left:auto;
    padding-right:0.5vh;
  }

  .upgradeDescription{
    position:relative;
    margin-top:-3vh;
    padding-left:1vh;
  }

  .storeTitle{
    text-align:center;
  }

  .store{
   overflow-y:auto;
   overflow-x:hidden;
  }

  

  .storeItem{
    position:relative;
    padding:0.3vh;
    height:8vh;
    display:grid;
    grid-template-columns: 20% 80%;
    grid-template-rows: 100%;
    column-gap:1vh;
    cursor:pointer;
    border:1px solid  rgb(238, 238, 197);
    margin-bottom:1vh;
    border-radius:2vw;
    background-image:linear-gradient(to bottom right, rgb(238, 238, 197) 25%,rgb(56, 151, 56));
  } 

  .storeItemNoBuy{
    position:relative;
    padding:0.3vh;
    height:8vh;
    display:grid;
    grid-template-columns: 20% 80%;
    grid-template-rows: 100%;
    column-gap:1vh;
    cursor:pointer;
    border:1px solid rgb(192, 196, 192);
    margin-bottom:1vh;
    border-radius:2vw;
    background-color:rgb(192, 196, 192);
  }

  .mysteryItem{
    position:relative;
    padding:0.3vh;
    height:8vh;
    display:grid;
    grid-template-columns: 20% 80%;
    grid-template-rows: 100%;
    column-gap:1vh;
    cursor:pointer;
    border:1px solid  rgb(238, 238, 197);
    margin-bottom:1vh;
    border-radius:2vw;
    background-image:linear-gradient(to right, rgb(4, 4, 4) 23%, rgb(63, 107, 63)25%,rgba(129, 216, 129, 0.958));
  } 

    .storeItem:hover{
      background-image:none;
      background-color: rgb(243, 235, 190);
    }

  .storeItem img{
    object-fit:contain;
    padding-right:1.5vw;
    padding-left:0.1vw;
  }

  .storeItemNoBuy img{
    object-fit:contain;
    padding-right:1.5vw;
    padding-left:0.1vw;
  }

  .mysteryItem img{
    object-fit:contain;
    padding-right:1.5vw;
    padding-left:0.1vw;
    border-radius:3vw;
  }

  .storeRight{
    object-fit:contain;
    display:grid;
    grid-template-columns:100%;
    grid-template-rows:50% 30%;
  }

  .storePrice{
    margin:auto 0;
    padding-left:1vw;
    font-size:calc(1vw + 5px);
    text-align:left;
    vertical-align:top;
  }

  .storePicture{
    
    height:100%;
    width:100%;
    margin-right:1vw;
  }

  .mysteryPicture{
    
    height:100%;
    width:100%;
    margin-right:1vw;
    filter:brightness(1%);
  }

  .storeWord{
    margin:auto 0;
    padding-left:1vw;
    font-size:2vw;
    text-align:left;
    vertical-align:top;
  
  }

  .storeAmount{
    position:absolute;
    right:1vw;
    align-items:center;
    font-size:2vw;
    color:rgb(30, 81, 30);

  }

  

  .storeBlurb{
    position:absolute;
    top:-3vh;
    right:35%;
    width:400px;
    max-width:60%;
    height:-25vh;
    padding:0;
    background-color:rgb(172, 158, 158);
    border-radius:2vw;
    
  }

  .blurb{
    position:absolute;
    min-height:min-content;
    top:-3vh;
    right:20%;
    width:fit-content;
    max-width:60%;
    height:fit-content;
    color:rgb(168, 217, 178);
    background-color:rgb(33, 104, 67);
    border-radius:2vw;
    margin-bottom:5px;
    z-index:20;
  }

  .preBlurb{
    position:absolute;
    top:-3vh;
    right:20%;
    width:fit-content;
    max-width:60%;
    height:fit-content;
    color:rgb(168, 217, 178);
    background-color:rgb(85, 96, 90);
    border-radius:2vw;
    margin-bottom:5px;
    z-index:20;
  }

  .lilBlurbTitle{
    margin-top:1vw;
    margin-bottom:0.1vw;
    margin-right:.5vw;
    padding:1px 1px 0 6px;
    text-decoration:underline;
  }

  .lilBlurbDescribe{
    font-size:10px;
    margin-top:1px;
    margin-bottom:1px;
    margin-right:10px;
    margin-left:3vw;
    padding:10px 10px 1.5px 12px;
  }

  .noStoreBlurb{
   display:none
  }

  .bold{
    font-weight:bold;
  }


  .bottom{
    position:absolute;
    bottom:200px;
  }

  .goldenPictureOff{
    display:none
  }


  .instructionsScreen{
    padding:2vw;
    align-items:center;
    display:flex;
    flex-wrap:wrap;
    height:90%;
    overflow-y:auto;
    margin:auto;
    justify-content:left; 
  }

  .saved{
    position:fixed;
    top:-15px;
    right:10px;
    color:rgb(19, 73, 19);
  }

  .spoilerMode{
    margin-top:2vh;
  }

  


  /* timer styles*/

  
  .base-timer {
    position: relative;
    width:8vh;
    height: 8vh;
    margin:auto;
    padding-top:1vh
  }
  
  .base-timer__svg {
    transform: scaleX(-1);
  }
  
  .base-timer__circle {
    fill: none;
    stroke: none;
  }
  
  .base-timer__path-elapsed {
    stroke-width: 10px;
    stroke: grey;
  }
  
  .base-timer__path-remaining {
    stroke-width: 10px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;
  }
  
  .base-timer__path-remaining.green {
    color: rgb(65, 184, 131);
  }
  
  .base-timer__path-remaining.orange {
    color: orange;
  }
  
  .base-timer__path-remaining.red {
    color: red;
  }
  
  .base-timer__label {
    position: absolute;
    width: 8vh;
    height: 8vh;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5vh;
    margin:auto;
    padding-top:.7vh;
    font-weight:800;
    letter-spacing:1px
  } 

 .noTimer{
  display:none;
 }